import DashboardLayout from "./dashboardLayout";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import notificationSound from "../assets/audio.mp3";

function OrderWithPrescription() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("pending");
  const [page, setPage] = useState(1);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [size, setSize] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [cancelMerchantId, setCancelMerchantId] = useState(null);
  const [cancelReason, setCancelReason] = useState("");
  const cancelReasons = [
    "wrong prescription",
    "medicine not available",
    "shop closed",
    "no response",
  ];

  const previousPendingCountRef = useRef(0);
  const audio = new Audio(notificationSound);

  const timeConversion = (currentDate) => {
    if (!currentDate || isNaN(new Date(currentDate).getTime())) return null;

    let hours = currentDate.getUTCHours();
    let minutes = currentDate.getUTCMinutes().toString();

    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }

    let period = hours >= 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }

    const date = currentDate.getUTCDate();
    const month = currentDate.getUTCMonth() + 1;
    const year = currentDate.getUTCFullYear();

    return `${date}/${month}/${year} ${hours}:${minutes} ${period}`;
  };

  const fetchOrders = async (status, page, size, showToast = false) => {
    setLoading(true);
    setError("");

    const fetchPromise = axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/admin/getPendingOrderWithPrescription`,
      { filterKey: status, page, size },
      { headers: { Authorization: `${sessionStorage.getItem("authToken")}` } }
    );

    const fetchOrdersPromise = showToast
      ? toast.promise(fetchPromise, {
          loading: "Fetching orders...",
          success: "Orders fetched!",
          error: "Failed to fetch orders.",
        })
      : fetchPromise;

    fetchOrdersPromise
      .then((response) => {
        if (response.data.success) {
          const fetchedOrders =
            response.data.data.pendingOrderWithPrescription || [];
          setOrders(fetchedOrders);

          if (audioEnabled) {
            const newPendingCount = fetchedOrders.length;
            if (newPendingCount > previousPendingCountRef.current) {
              audio.play();
            } else if (newPendingCount < previousPendingCountRef.current) {
              audio.pause();
            }
            previousPendingCountRef.current = newPendingCount;
          }
        } else {
          setError("Failed to fetch order details.");
        }
      })
      .catch(() => {
        setError("An error occurred while fetching order details.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders(filter, page, size, true);
    if (filter === "pending") {
      const intervalId = setInterval(() => {
        fetchOrders(filter, page, size, true);
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [filter, page, size]);

  const cancelOrder = async () => {
    if (!cancelOrderId || !cancelReason) {
      toast.error("Please select a reason for cancellation.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/cancelOrderWithPrescription`,
        {
          orderWithPrescriptionId: cancelOrderId,
          merchantId: cancelMerchantId,
          cancelledReason: cancelReason,
        },
        { headers: { Authorization: `${sessionStorage.getItem("authToken")}` } }
      );

      if (response.data.success) {
        toast.success("Order cancelled successfully!");
        fetchOrders(filter, page, size, true);
      } else {
        toast.error("Failed to cancel order.");
      }
    } catch (error) {
      toast.error("An error occurred while cancelling the order.");
    }
    setCancelModalOpen(false);
  };

  return (
    <DashboardLayout>
      <div className="text-3xl font-bold mb-6">
        <h1 className="text-3xl font-bold mb-6">Order Status</h1>
        <div className="mb-8 flex space-x-4">
          {["all", "pending", "accepted", "confirmed"].map((status) => (
            <button
              key={status}
              className={`px-6 py-2 rounded-full transition duration-200 ${
                filter === status
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700 hover:bg-gray-400"
              }`}
              onClick={() => setFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ))}
        </div>
        {orders.length === 0 ? (
          <h1 className="text-center bg-gray-500">No orders present</h1>
        ) : (
          <div>
            {orders.map((order) => (
              <div
                key={order._id}
                className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200"
              >
                <h2 className="font-semibold text-xl mb-4">
                  <strong>Order ID:</strong> {order._id}
                </h2>
                <p className="text-xl">
                  <strong>Merchant Name:</strong> {order.merchantDetails.shopName}
                </p>
                <p className="text-xl font-semibold">
                  <strong>Merchant Phone:</strong> {order.merchantDetails.phoneNumber}
                </p>
                <br></br>
                <h2 className="font-semibold text-xl mb-4">
                  <strong>User Id:</strong> {order.userId}
                </h2>
                {/* <p className="text-xl font-semibold">
                  <strong>Customer Registered Number:</strong> {order.customerPhoneNumber}
                </p> */}
                <p className="text-xl font-semibold">
                  <strong>Customer Address Number:</strong> {order.addressDetails.receiverPhoneNumber}
                </p>
                <p className="text-xl font-semibold">
                  <strong>Customer Name:</strong> {order.addressDetails.receiverName}
                </p>
                <p className="text-xl font-semibold">
                  <strong>Customer Address:</strong> {order.addressDetails.addressLine1 + ", " + order.addressDetails.addressLine2}
                </p>
                <br></br>
                <p className="text-xl font-semibold">
                  <strong>Order Placed Time:</strong> {timeConversion(new Date(order.orderPlacedTime))}
                </p>
                <p className="text-xl font-semibold">
                  <strong>Status:</strong> {order.status}
                </p>
                <br></br>

                {order.status === "pending" && (
                  <button
                    className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-700"
                    onClick={() => {
                      setCancelOrderId(order._id);
                      setCancelMerchantId(order.merchantId);
                      setCancelModalOpen(true);
                    }}
                  >
                    Cancel Order
                  </button>
                )}

                <button
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
                  onClick={() => {
                    console.log("Order with prescription:", order);

                    // Check if prescriptionUrls exists and is an array before mapping
                    const prescriptionUrl = order?.prescriptionUrl;
                    console.log(
                      "Prescription URLs from API:",
                      prescriptionUrl
                    );

                    if (
                      !prescriptionUrl ||
                      !Array.isArray(prescriptionUrl) ||
                      prescriptionUrl.length === 0
                    ) {
                      console.error("No valid prescription URLs found");
                      return toast.error("No prescription images available!");
                    }

                    // Transform URLs for display
                    const formattedImages = prescriptionUrl.map(
                      (url) => `https://d2l55457kk8x11.cloudfront.net/${url}`
                    );
                    console.log("Formatted Image URLs:", formattedImages);

                    setSelectedImages(formattedImages);
                    setModalOpen(true);
                  }}
                >
                  View Prescription
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {cancelModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-96">
            <h2 className="text-xl font-semibold mb-4">Are you sure you want to cancel this order?</h2>
            <select
              className="w-full p-2 border rounded mb-4"
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            >
              <option value="">Select Reason</option>
              {cancelReasons.map((reason, index) => (
                <option key={index} value={reason}>{reason}</option>
              ))}
            </select>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-300 rounded-lg"
                onClick={() => setCancelModalOpen(false)}
              >
                No
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
                onClick={cancelOrder}
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {modalOpen && (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    onClick={() => setModalOpen(false)} // Close when clicking outside
  >
    <div
      className="bg-white p-4 rounded-lg shadow-lg relative w-80 flex flex-col items-center overflow-x-auto whitespace-nowrap"
      onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside
    >
      <button
        className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full"
        onClick={() => setModalOpen(false)}
      >
        X
      </button>
      <div className="flex">
        {selectedImages.length === 0 ? (
          <p className="text-center text-red-500">No images found</p>
        ) : (
          <div className="flex">
            {selectedImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Prescription ${index + 1}`}
                className="w-60 h-60 transform transition duration-300 hover:scale-105 object-contain"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)}

    </DashboardLayout>
  );
}

export default OrderWithPrescription;
