import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout";
import toast, { Toaster } from "react-hot-toast";
import notificationSound from "../assets/audio.mp3";
import OrderDetailsSearch from "../components/search-bar";
import Modal from "react-modal";
const OrderStatus = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(
    () => sessionStorage.getItem("orderFilter") || "all"
  );
  const [page, setPage] = useState(1);
  const [size] = useState(5);
  const [selectedOrder, setSelectedOrder] = useState(null); // Track selected order for cancellation
  const [cancelReason, setCancelReason] = useState(""); // Track the selected reason
  const previousPendingCountRef = useRef(0); // Track the previous number of pending orderss
  const audio = new Audio(notificationSound);
  const getAdminRole = sessionStorage.getItem("adminRole");
  //moodal

  const [selectedItems, setSelectedItems] = useState([]); // Track selected items
  const [specialOrderProducts, setSpecialOrderProducts] = useState([]); // Items selected for split
  const [instantDeliveryProducts, setInstantDeliveryProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [instantDeliveryQuantity, setInstantDeliveryQuantity] = useState(0);


  const openModal = (items, id) => {
    console.log(id);

    setModalItems(items);
    setIsModalOpen(true);
    setOrderId(id);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalItems([]);
  };

  

const handleSelectChange = (item, value, quantity) => {
  const parsedValue = parseInt(value);

    setSelectedOptions((prev) => ({
    ...prev,
    [item.productId]: { value, quantity: parsedValue }, // Store both the value and quantity
  }));


  setSpecialOrderProducts(prev => {
    const existingItem = prev.find(i => i.productId === item.productId);
    
    if (existingItem) {
      return prev.map(i => 
        i.productId === item.productId ? { ...i, quantity: parsedValue } : i
      );
    }
    return [...prev, { ...item, quantity: parsedValue }];
  });
  
  setInstantDeliveryProducts(prev => {
    const existingItem = prev.find(i => i.productId === item.productId);
    if (existingItem) {
      return prev.map(i => 
        i.productId === item.productId ? { ...i, quantity: item.quantity - parsedValue } : i
      );
    }
    return [...prev, { ...item, quantity: item.quantity - parsedValue }];
  });
};



  const fetchOrders = async (
    status = "all",
    page = 1,
    size = 5,
    showToast = false
  ) => {
    setLoading(true);
    setError("");

    const fetchPromise = axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/admin/allOrderDetails`,
      {
        filterKey: status,
        page,
        size,
      },
      {
        headers: {
          Authorization: `${sessionStorage.getItem("authToken")}`,
        },
      }
    );

    const fetchOrdersPromise = showToast
      ? toast.promise(fetchPromise, {
          loading: "Refreshing orders...",
          success: "Orders refreshed!",
          error: "Failed to refresh orders.",
        })
      : fetchPromise;

    fetchOrdersPromise
      .then((response) => {
        if (response.data.success) {
          setOrders(response.data.data.orders);
          setFilteredOrders(response.data.data.orders);

          if (status === "pending") {
            const newPendingCount = response.data.data.orders?.length ?? 0;

            // Check if there are new pending orders

            console.log(newPendingCount);
            console.log(previousPendingCountRef.current);
            if (newPendingCount > previousPendingCountRef.current) {
              audio.play();
              console.log("pending notify sound");
            } else if (newPendingCount < previousPendingCountRef.current) {
              audio.pause();
              console.log("pending notify sound paused");
            }

            // Update the previous pending count
            previousPendingCountRef.current = newPendingCount;
          }
        } else {
          setError("Failed to fetch order details.");
        }
      })
      .catch(() => {
        setError("An error occurred while fetching order details.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders(filter, page, size, true);
    if (filter === "pending") {
      const intervalId = setInterval(() => {
        fetchOrders(filter, page, size, true);
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [filter, page, size]);

  const handleFilterChange = (status) => {
    setFilter(status);
    setPage(1);
    sessionStorage.setItem("orderFilter", status);
    fetchOrders(status, 1, size, false);
  };

  const handlePageChange = (direction) => {
    if (direction === "next") {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Handle checkbox change

  
  //new

  const handleCheckboxChange = (item, isChecked, type) => {
    if (isChecked) {
      const quantity = selectedOptions[item.productId]?.quantity || 1; // Default to 1 if no quantity selected

      if (type === "split") {
        setSpecialOrderProducts((prev) => [
          ...prev,
          { ...item, quantity },
        ]);
        setInstantDeliveryProducts((prev) =>
          prev.filter((i) => i._id !== item._id)
        );
      } else {
        setInstantDeliveryProducts((prev) => [
          ...prev,
          { ...item, quantity },
        ]);
        setSpecialOrderProducts((prev) =>
          prev.filter((i) => i._id !== item._id)
        );
      }
    } else {
      if (type === "split") {
        setSpecialOrderProducts((prev) =>
          prev.filter((i) => i._id !== item._id)
        );
      } else {
        setInstantDeliveryProducts((prev) =>
          prev.filter((i) => i._id !== item._id)
        );
      }
    }
  };
  

  const handleSplitOrder = async () => {
    // Construct the payload with selected data

    const payload = {
      instantDeliveryProducts: instantDeliveryProducts,
      specialOrderProducts: specialOrderProducts,
      timeLeft: selectedDeliveryTime,
      orderId: orderId,
    };

    console.log({payload});
    

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/superadmin/splitOrder`,
        payload,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      ); // Make the POST request to the API
      setLoading(false);

      if (response.data.success) {
        toast.success("Order split successfully!");
        closeModal(); // Close the modal after successful split
      } else {
        toast.error("Failed to split the order. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while splitting the order.");
      console.error("Error splitting order:", error);
    }
  };

  const handleDeliveryTimeChange = (item, time) => {
    setDeliveryTimes(time);
  };

  //new 

  // const handleSplitOrder = async () => {
  //   // Construct the payload with selected data
  //   const splitProducts = modalItems.map((item) => {
  //     const selectedOption = selectedOptions[item.productId]; // Get the selected option
  //     const quantity = selectedOption ? selectedOption.quantity : 0;
  
  //     const specialOrderQuantity = Math.floor(quantity / 2); // Half goes to specialOrder
  //     const instantDeliveryQuantity = quantity - specialOrderQuantity; // The rest goes to instantDelivery
  
  //     return {
  //       ...item,
  //       quantity: specialOrderQuantity, // Adjust quantity for specialOrder
  //     };
  //   });
  
  //   const instantDeliveryPayload = splitProducts.map((item) => ({
  //     ...item,
  //     quantity: item.quantity === 0 ? 0 : instantDeliveryQuantity,
  //   }));
  
  //   const payload = {
  //     instantDeliveryProducts: instantDeliveryPayload,
  //     specialOrderProducts: splitProducts,
  //     timeLeft: selectedDeliveryTime,
  //     orderId: orderId,
  //   };
  
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/admin/superadmin/splitOrder`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `${sessionStorage.getItem("authToken")}`,
  //         },
  //       }
  //     );
  //     setLoading(false);
  
  //     if (response.data.success) {
  //       toast.success("Order split successfully!");
  //       closeModal(); // Close the modal after successful split
  //     } else {
  //       toast.error("Failed to split the order. Please try again.");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("An error occurred while splitting the order.");
  //     console.error("Error splitting order:", error);
  //   }
  // };
  


  const handleCancelOrder = async () => {
    if (!selectedOrder || !cancelReason) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/cancelOrderFromAdmin`,
        {
          orderId: selectedOrder._id,
          cancelledReason: cancelReason,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        const filteredOrders = response.data.data.orders.filter(
          (order) => order.merchantShopName !== "Demo Account"
        );
        toast.success("Order cancelled successfully.");
        filteredOrders(filter, page, size, false);
        setSelectedOrder(null);
        setCancelReason("");
      } else {
        toast.error("Failed to cancel the order.");
      }
    } catch (error) {
      toast.error("An error occurred while cancelling the order.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  const timeConversion = (currentDate) => {
    if (!currentDate || isNaN(new Date(currentDate).getTime())) return null;

    let hours = currentDate.getUTCHours();
    let minutes = currentDate.getUTCMinutes().toString();

    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }

    let period = hours >= 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }

    const date = currentDate.getUTCDate();
    const month = currentDate.getUTCMonth() + 1;
    const year = currentDate.getUTCFullYear();

    return `${date}/${month}/${year} ${hours}:${minutes} ${period}`;
  };

  const calculateDeliveryTime = (packedTime, deliveredTime) => {
    const diffInMs = deliveredTime - packedTime; // Difference in milliseconds
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert to hours
    const diffInMinutes = Math.floor(
      (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
    ); // Get remaining minutes

    return `${diffInHours} hours and ${diffInMinutes} minutes`;
  };

  const handleHoldOrder = async (orderId) => {
    try {
      console.log("Order ID being sent:", orderId);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/superadmin/holdOrder`,
        { orderId },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Order has been placed on hold.");
        fetchOrders(filter, page, size, false); // Refresh the orders list
      } else {
        toast.error("Failed to place the order on hold.");
      }
    } catch (error) {
      console.log(error);

      toast.error("An error occurred while holding the order.");
    }
  };

 
  

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100 min-h-screen">
          <Toaster />
          <OrderDetailsSearch />
          <h1 className="text-3xl font-bold mb-6">Order Status</h1>
          <div className="mb-8 flex space-x-4">
            {["all", "pending", "confirmed", "delivered", "refunded"].map(
              (status) => (
                <button
                  key={status}
                  className={`px-6 py-2 rounded-full transition duration-200 ${
                    filter === status
                      ? {
                          all: "bg-blue-600 text-white",
                          pending: "bg-yellow-600 text-white",
                          confirmed: "bg-green-600 text-white",
                          delivered: "bg-blue-600 text-white",
                          refunded: "bg-red-600 text-white",
                        }[status]
                      : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                  }`}
                  onClick={() => handleFilterChange(status)}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </button>
              )
            )}
          </div>

          {filteredOrders.length === 0 ? (
            <div className="text-center text-gray-500">
              <p>No orders with status '{filter}' are present.</p>
            </div>
          ) : (
            <div>
              {filteredOrders.map(
                (order) => (
                  console.log(
                    "Order Status:",
                    order.status,
                    "Is Hold:",
                    order.isHold
                  ),
                  (
                    <div
                      key={order._id}
                      className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200"
                    >
                      <h2 className="text-xl font-semibold mb-4">
                        Order ID: {order.orderId}{" "}
                      </h2>

                      <div className="flex space-x-4">
                        {order.status === "hold" && !order.isSplitted ? (
                          <button
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                            onClick={() =>
                              openModal(order.orderItems, order._id)
                            }
                          >
                            Split Order
                          </button>
                        ) : (
                          <button
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded cursor-not-allowed"
                            disabled
                          >
                            Split Order (On Hold Only)
                          </button>
                        )}
                      </div>

                      <p className="mb-4">
                        ID: {order._id}{" "}
                        <span className="text-[1.1rem] italic font-medium">
                          (This ID is used for checking split settlements)
                        </span>
                      </p>
                      {getAdminRole === "masteradmin" && (
                        <>
                          {order.status === "pending" ||
                          order.status === "confirmed" ? (
                            <div>
                              <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={() => setSelectedOrder(order)}
                              >
                                Cancel Order
                              </button>
                            </div>
                          ) : null}

                          {selectedOrder && selectedOrder._id === order._id && (
                            <div className="mt-4">
                              <select
                                className="border border-gray-300 rounded px-3 py-2 w-full"
                                value={cancelReason}
                                onChange={(e) =>
                                  setCancelReason(e.target.value)
                                }
                              >
                                <option value="">
                                  Select Cancellation Reason
                                </option>
                                <option value="due to customer">
                                  Due to Customer
                                </option>
                                <option value="due to merchant">
                                  Due to Merchant
                                </option>
                                <option value="due to vyloo">
                                  Due to Vyloo
                                </option>
                              </select>
                              <button
                                className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                                onClick={handleCancelOrder}
                              >
                                OK
                              </button>
                            </div>
                          )}
                        </>
                      )}

                      {order.status === "delivered" && (
                        <>
                          <p className="text-xl mb-4 text-green-600">
                            Delivered in{" "}
                            <strong>
                              {calculateDeliveryTime(
                                new Date(order.orderAcceptedTime),
                                new Date(order.orderDeliveredTime)
                              )}
                            </strong>
                          </p>
                        </>
                      )}

                      <h2 className="text-xl font-semibold">
                        Merchant Name : {order.merchantShopName}
                      </h2>
                      <p>Merchant Id: {order.merchantId}</p>
                      <p>
                        Merchant Address: {order.merchantAddress1},{" "}
                        {order.merchantAddress2}
                      </p>
                      <p className="mb-4">
                        Merchant Phone number : {order.merchantMobileNumber}
                      </p>
                      <h2 className="text-xl font-semibold">
                        Delivery Partner ID: {order.deliveryPartnerId}
                      </h2>
                      <p>Delivery Partner Name: {order.deliveryPartnerName}</p>
                      <p className="mb-4">
                        Delivery Partner Number :{" "}
                        {order.deliveryPartnerMobileNumber}
                      </p>

                      <h2 className="text-xl font-semibold">
                        User ID: {order.deliveryPartnerId}
                      </h2>
                      <p>User Name: {order.userName}</p>
                      <p>User Number : {order.userMobileNumber}</p>
                      <p>
                        User Address: {order.userAddress1}, {order.userAddress2}
                      </p>
                      <br />
                      {/* Displaying Ordered Items */}
                      <div className="mb-4">
                        <h2 className="text-lg font-semibold">
                          Ordered Items:
                        </h2>
                        {order.orderItems.map((item) => (
                          <div key={item.productId} className="mb-2">
                            <p>
                              <strong>Product:</strong> {item.displayName} (
                              {item.packaging})
                            </p>
                            <p>
                              <strong>Product Type:</strong> {item.productType}
                            </p>
                            <p>
                              <strong>Quantity:</strong> {item.quantity}
                            </p>
                            <p>
                              <strong>Price:</strong> ₹{item.price}
                            </p>
                            <img
                              src={`https://d2l55457kk8x11.cloudfront.net/${item.displayImagesUrl}`}
                              alt={item.displayName}
                              className="w-20 h-20 object-cover"
                            />
                          </div>
                        ))}
                      </div>

                      <div className="mb-4">
                        <p>
                          <strong>Order Placed Time:</strong>{" "}
                          {timeConversion(new Date(order.orderPlacedTime))}
                        </p>
                        <p>
                          <strong>Order Accepted Time:</strong>{" "}
                          {timeConversion(new Date(order.orderAcceptedTime))}
                        </p>
                        <p>
                          <strong>Order Packed Time:</strong>{" "}
                          {timeConversion(new Date(order.orderPackedTime))}
                        </p>
                        <p className="mb-4">
                          <strong>Order Delivered Time:</strong>{" "}
                          {timeConversion(new Date(order.orderDeliveredTime))}
                        </p>

                        <p className="mb-4">
                          <strong>Status:</strong> {order.status}
                        </p>
                        <p className="mb-4">
                          <strong>Cancelled Reason</strong>{" "}
                          {order.cancelledReason}
                        </p>

                        <p>
                          <strong>Order Packed:</strong>{" "}
                          {order.isPacked ? "Yes" : "No"}
                        </p>
                        <p>
                          <strong>Order Picked:</strong>{" "}
                          {order.isPicked ? "Yes" : "No"}
                        </p>
                        <p className="mb-4">
                          <strong>Order Delivered:</strong>{" "}
                          {order.isDelivered ? "Yes" : "No"}
                        </p>
                        <p>
                          <strong>Payment Method:</strong> {order.paymentMethod}
                        </p>
                        <p>
                          <strong>Payment Status:</strong> {order.paymentStatus}
                        </p>
                        <p>
                          <strong>Total Amount:</strong> ₹{order.totalAmount}
                        </p>
                        <p className="mb-4">
                          <strong>Grand Total:</strong> ₹{order.grandTotal}
                        </p>
                        <p>
                          <strong>Prescription Available?:</strong>{" "}
                          {order.prescriptionAvailable ? "Yes" : "No"}
                        </p>
                      </div>

                      <div className="flex justify-between">
                        {order.prescriptionAvailable && (
                          <a
                            href={`https://d2l55457kk8x11.cloudfront.net/${order.prescriptionUrl}`}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                            target="_blank"
                            rel="noopener noreferrer" // Security measure
                          >
                            View Prescription
                          </a>
                        )}
                        {order.status !== "cancelled" && (
                          <>
                            <a
                              href={`https://d2l55457kk8x11.cloudfront.net/${order.billReceiptImageUrl}`}
                              className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                              target="_blank"
                              rel="noopener noreferrer" // Security measure
                            >
                              Bill Receipt
                            </a>
                            <button
                              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 hover:scale-105 transition-all duration-200"
                              onClick={() => handleHoldOrder(order._id)}
                            >
                              Hold Order
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )
                )
              )}
            </div>
          )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="modal-content relative bg-white rounded-lg max-w-2xl mx-auto p-6 max-h-[80vh] overflow-y-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            ariaHideApp={false} // Suppress accessibility warnings
          >
            <h2 className="text-2xl font-bold mb-4 text-red-300">
              Order Items
            </h2>
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-black"
              onClick={closeModal}
            >
              Close
            </button>
            <div className="space-y-4 max-h-[70vh] overflow-y-auto">
              {modalItems.map((item) => (
                <div
                  key={item.productId}
                  className="flex items-center space-x-4 border-b pb-4"
                >
                  <img
                    src={`https://d2l55457kk8x11.cloudfront.net/${item.displayImagesUrl}`}
                    alt={item.displayName}
                    className="w-20 h-20 object-cover"
                  />
                  <div>
                    <p>
                      <strong>Product:</strong> {item.displayName}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> ₹{item.price}
                    </p>
                  </div>
                  {item.quantity > 1 && (
                    <div className="ml-3">
                      <label className="mr-2">
                        <strong>Select Quantity:</strong>
                        <select
                          value={selectedOptions[item.productId]?.quantity || ""}
                          onChange={(e) =>
                            handleSelectChange(item, e.target.value,item.quantity)
                          }
                          className="ml-2 p-1 border rounded"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {Array.from(
                            { length: item.quantity - 1 },
                            (_, index) => index + 1
                          ).map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </label>
                     
                    </div>
                  )}

             

                  <div className="flex items-center space-x-2">
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(
                              item,
                              e.target.checked,
                              "split"
                            )
                          }
                          checked={specialOrderProducts.some(
                            (i) => i._id === item._id
                          )}
                        />
                        Split
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(
                              item,
                              e.target.checked,
                              "instant"
                            )
                          }
                          checked={instantDeliveryProducts.some(
                            (i) => i._id === item._id
                          )}
                        />
                        Instant Delivery
                      </label>
                    </div>
                  </div>

                  {/* <div>
                    <label
                      htmlFor={`delivery-time-${item._id}`}
                      className="block text-sm"
                    >
                      Select Delivery Time:
                    </label>
                    <select
                      id={`delivery-time-${item._id}`}
                      value={deliveryTimes[item._id] || ""}
                      onChange={(e) =>
                        handleDeliveryTimeChange(item, e.target.value)
                      }
                      className="mt-1 p-2 border rounded"
                    >
                      <option value="">Select time</option>
                      <option value="30 min">30 min</option>
                      <option value="1 hour">1 hour</option>
                      <option value="2 hours">2 hours</option>
                      <option value="3 hours">3 hours</option>
                      <option value="4 hours">4 hours</option>
                      <option value="1 day">1 day</option>
                    </select>
                  </div> */}
                </div>
              ))}

              {/* <div className="mt-4">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleSplitOrder}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Split Selected Items"}
                </button>
                <div>
                    <label
                      htmlFor={`delivery-time-${item._id}`}
                      className="block text-sm"
                    >
                      Select Delivery Time:
                    </label>
                    <select
                      id={`delivery-time-${item._id}`}
                      value={deliveryTimes[item._id] || ""}
                      onChange={(e) =>
                        handleDeliveryTimeChange(item, e.target.value)
                      }
                      className="mt-1 p-2 border rounded"
                    >
                      <option value="">Select time</option>
                      <option value="30 min">30 min</option>
                      <option value="1 hour">1 hour</option>
                      <option value="2 hours">2 hours</option>
                      <option value="3 hours">3 hours</option>
                      <option value="4 hours">4 hours</option>
                      <option value="1 day">1 day</option>
                    </select>
                  </div>
              </div> */}
            </div>

            <div className="flex justify-between items-center mt-4">
              <select
                className="border border-gray-300 rounded px-3 py-2"
                value={selectedDeliveryTime}
                onChange={(e) => setSelectedDeliveryTime(e.target.value)}
              >
                <option value="">Select Delivery Time</option>
                <option value="30 min">30 min</option>
                <option value="1 hour">1 hour</option>
                <option value="2 hours">2 hours</option>
                <option value="3 hours">3 hours</option>
                <option value="4 hours">4 hours</option>
                <option value="1 day">1 day</option>
              </select>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleSplitOrder}
                  disabled={loading || !selectedDeliveryTime}
                >
                  {loading ? "Processing..." : "Split Selected Items"}
                </button>
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                  onClick={() => setSelectedDeliveryTime("")}
                >
                  Reset Time
                </button>
              </div>
            </div>
          </Modal>

          {/* Pagination */}
          <div className="flex justify-center space-x-4 mt-4">
            <button
              onClick={() => handlePageChange("previous")}
              className={`px-4 py-2 rounded ${
                page === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
              }`}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 rounded bg-gray-200">{page}</span>
            <button
              onClick={() => handlePageChange("next")}
              className="px-4 py-2 rounded bg-blue-500 text-white"
            >
              Next
            </button>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default OrderStatus;
