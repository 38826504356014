import React, { useState, useEffect } from 'react';
import DashboardLayout from "./dashboardLayout";
import axios from 'axios';

export default function AddToCartProduct() {
  const [medicines, setMedicines] = useState([]);
  const [otcs, setOtcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [showMedicines, setShowMedicines] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [userCartDetails, setUserCartDetails] = useState(null);
  const [userSearchError, setUserSearchError] = useState("");

  const fetchMedicines = async () => {
    setLoading(true);
    setError("");

    try {
      const token = sessionStorage.getItem("authToken");
      let url = `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllProducts`;

      if (filter === "active") {
        url = `${url}?status=active`;
      } else if (filter === "inactive") {
        url = `${url}?status=inactive`;
      }

      let params = {
        page,
        size: 12,
        productType: "medicines",
        query: searchQuery,
      };

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data.success) {
        const productsData = response.data.data.products.flatMap(
          (productGroup) => productGroup.products
        );
        setMedicines(productsData);
      } else {
        setError("Failed to fetch medicines.");
      }
    } catch (error) {
      setError("An error occurred while fetching medicines.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOtcs = async () => {
    setLoading(true);
    setError("");

    try {
      const token = sessionStorage.getItem("authToken");
      let url = `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllProducts`;

      if (filter === "active") {
        url = `${url}?status=active`;
      } else if (filter === "inactive") {
        url = `${url}?status=inactive`;
      }

      let params = {
        page,
        size: 12,
        productType: "otc",
        query: searchQuery,
      };

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data.success) {
        const productsData = response.data.data.products.flatMap(
          (productGroup) => productGroup.products
        );
        setOtcs(productsData);
      } else {
        setError("Failed to fetch OTC products.");
      }
    } catch (error) {
      setError("An error occurred while fetching OTC products.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showMedicines) {
      fetchMedicines();
    } else {
      fetchOtcs();
    }
  }, [page, searchQuery, filter, showMedicines]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handlePageChange = (direction) => {
    if (direction === "next") {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleAddToCart = (product) => {
    const existingItem = cartItems.find(item => item._id === product._id);
    if (existingItem) {
      setCartItems(cartItems.map(item =>
        item._id === product._id
          ? { ...item, quantity: Number(item.quantity) + 1, mrp: Number(item.mrp) }
          : item
      ));
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1, mrp: Number(product.mrp) }]);
    }
  };

  const handleRemoveFromCart = (productId) => {
    setCartItems(cartItems.filter(item => item._id !== productId));
  };

  const handleUpdateQuantity = (productId, newQuantity) => {
    if (Number(newQuantity) < 1) return;
    setCartItems(cartItems.map(item =>
      item._id === productId
        ? { ...item, quantity: Number(newQuantity), mrp: Number(item.mrp) }
        : item
    ));
  };

  const calculateTotal = () => {
    const total = cartItems.reduce((total, item) => {
      const price = parseFloat(item.mrp) || 0;
      const quantity = parseInt(item.quantity) || 0;
      return total + (price * quantity);
    }, 0);
    return parseFloat(total.toFixed(2));
  };

  const handlePhoneNumberSearch = async () => {
    setUserSearchError("");
    setCartItems([]); // Clear cart when searching for a new user
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/getUserDetailsByMobileNumber`,
        { mobileNumber: Number(phoneNumber) },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        setUserDetails(response.data.data.userDetails);
        // Load existing cart items if available
        if (response.data.data.cartDetails && response.data.data.cartDetails.length > 0) {
          const formattedCartItems = response.data.data.cartDetails.map(item => ({
            ...item,
            quantity: item.frequency || 1,
            mrp: Number(item.mrp)
          }));
          setCartItems(formattedCartItems);
        }
      } else {
        setUserSearchError("User not found");
        setUserDetails(null);
        setCartItems([]);
      }
    } catch (error) {
      setUserSearchError("Error searching for user");
      setUserDetails(null);
      setCartItems([]);
    }
  };

  const updateUserCart = async () => {
    if (!userDetails) {
      alert("Please search for a user first");
      return;
    }

    try {
      const token = sessionStorage.getItem("authToken");
      const formattedCartItems = cartItems.map(item => ({
        _id: item._id,
        displayName: item.displayName || "",
        mrp: Number(item.mrp).toString(),
        imageUrl: item.imageUrl || "placeholder.png",
        quantity: item.packaging || "",
        frequency: Math.max(1, Number(item.quantity)),
        productType: showMedicines ? "medicines" : "otc",
        prescriptionRequired: item.prescriptionRequired || "NO",
        inCart: true
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/updateUserCartDetails`,
        {
          userId: userDetails._id,
          productList: formattedCartItems
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        alert("Cart updated successfully!");
        // Keep the original cart items structure for display
        setCartItems(cartItems.map(item => ({
          ...item,
          quantity: Math.max(1, Number(item.quantity))
        })));
      } else {
        alert("Failed to update cart.");
      }
    } catch (error) {
      alert("An error occurred while updating the cart.");
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6 bg-gray-100 flex space-x-4">
        <div className="flex flex-col items-center space-y-4 mb-6">
          <div className="w-full flex space-x-2">
            <input
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="p-2 border rounded-lg flex-grow"
            />
            <button
              onClick={handlePhoneNumberSearch}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Search
            </button>
          </div>

          {userSearchError && (
            <p className="text-red-500">{userSearchError}</p>
          )}
          {userDetails && (
            <div className="w-full bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-2">User Details</h3>
              <p>Name: {userDetails.name}</p>
              <p>Phone: {userDetails.mobileNumber}</p>
              <p>Email: {userDetails.email}</p>
            </div>
          )}

          <button
            className={`w-32 px-6 py-2 rounded-lg transition-colors duration-300 shadow-md ${showMedicines ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            onClick={() => setShowMedicines(true)}
          >
            Medicines
          </button>
          <button
            className={`w-32 px-6 py-2 rounded-lg transition-colors duration-300 shadow-md ${!showMedicines ? 'bg-green-600 text-white' : 'bg-green-500 text-white hover:bg-green-600'}`}
            onClick={() => setShowMedicines(false)}
          >
            OTC
          </button>
        </div>

        <div className="w-1/2 pr-4">
          {userDetails && (
            <div className="mb-4">
              <button
                onClick={updateUserCart}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Update Cart
              </button>
            </div>
          )}
          {showMedicines ? (
            <>
              <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search for a medicine..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="p-3 pl-10 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all w-full"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute left-3 top-3 w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
              </svg>
            </div>

            <div className="mb-4">
              <button
                onClick={() => setFilter("active")}
                className={`px-4 py-2 rounded-md mr-2 ${filter === "active" ? "bg-blue-600 text-white" : "bg-gray-300"}`}
              >
                Active
              </button>
            </div>

            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : medicines.length === 0 ? (
              <p>No medicines found.</p>
            ) : (
              <div className="space-y-4">
                {medicines.map((medicine) => (
                  <div
                    key={medicine._id}
                    className="bg-white shadow-lg rounded-lg overflow-hidden"
                  >
                    <div
                      className="p-4 cursor-pointer flex justify-between items-center"
                      onClick={() => setExpandedItem(expandedItem === medicine._id ? null : medicine._id)}
                    >
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900">{medicine.displayName}</h2>
                        <p className="text-gray-800 text-lg">₹{parseFloat(medicine.mrp).toFixed(2)}</p>
                      </div>
                      <svg
                        className={`w-6 h-6 transform transition-transform ${expandedItem === medicine._id ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                    {expandedItem === medicine._id && (
                      <div className="p-4 border-t">
                        <div className="flex items-start space-x-4">
                          {medicine.imageUrl && (
                            <img
                              src={`https://d2l55457kk8x11.cloudfront.net/${medicine.imageUrl}`}
                              alt={medicine.displayName}
                              className="w-24 h-24 object-cover rounded-lg"
                            />
                          )}
                          <div className="flex-1">
                            <p className="text-gray-600">Packaging: <span className="font-medium">{medicine.packaging}</span></p>
                            <p className="text-gray-600 mt-1">Manufacturer: <span className="font-medium">{medicine.manufacturer}</span></p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(medicine);
                              }}
                              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-center space-x-4 mt-6">
              <button
                onClick={() => handlePageChange("previous")}
                className={`px-4 py-2 rounded ${page === 1 ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-600"}`}
                disabled={page === 1}
              >
                Previous
              </button>
              <span className="px-4 py-2 rounded bg-gray-100 text-gray-800">
                {page}
              </span>
              <button
                onClick={() => handlePageChange("next")}
                className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search for an OTC product..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="p-3 pl-10 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all w-full"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute left-3 top-3 w-5 h-5 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
              </svg>
            </div>

            <div className="mb-4">
              <button
                onClick={() => setFilter("active")}
                className={`px-4 py-2 rounded-md mr-2 ${filter === "active" ? "bg-green-600 text-white" : "bg-gray-300"}`}
              >
                Active
              </button>
            </div>

            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : otcs.length === 0 ? (
              <p>No OTC products found.</p>
            ) : (
              <div className="space-y-4">
                {otcs.map((otc) => (
                  <div
                    key={otc._id}
                    className="bg-white shadow-lg rounded-lg overflow-hidden"
                  >
                    <div
                      className="p-4 cursor-pointer flex justify-between items-center"
                      onClick={() => setExpandedItem(expandedItem === otc._id ? null : otc._id)}
                    >
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900">{otc.displayName}</h2>
                        <p className="text-gray-800 text-lg">₹{parseFloat(otc.mrp).toFixed(2)}</p>
                      </div>
                      <svg
                        className={`w-6 h-6 transform transition-transform ${expandedItem === otc._id ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                    {expandedItem === otc._id && (
                      <div className="p-4 border-t">
                        <div className="flex items-start space-x-4">
                          {otc.imageUrl && (
                            <img
                              src={`https://d2l55457kk8x11.cloudfront.net/${otc.imageUrl}`}
                              alt={otc.displayName}
                              className="w-24 h-24 object-cover rounded-lg"
                            />
                          )}
                          <div className="flex-1">
                            <p className="text-gray-600">Packaging: <span className="font-medium">{otc.packaging}</span></p>
                            <p className="text-gray-600 mt-1">Manufacturer: <span className="font-medium">{otc.manufacturer}</span></p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddToCart(otc);
                              }}
                              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-300"
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-center space-x-4 mt-6">
              <button
                onClick={() => handlePageChange("previous")}
                className={`px-4 py-2 rounded ${page === 1 ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"}`}
                disabled={page === 1}
              >
                Previous
              </button>
              <span className="px-4 py-2 rounded bg-gray-100 text-gray-800">
                {page}
              </span>
              <button
                onClick={() => handlePageChange("next")}
                className="px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600"
              >
                Next
              </button>
            </div>
          </>
        )}
        </div>

        {/* Cart Section */}
        <div className="w-1/2 pl-4">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Shopping Cart</h2>
            {cartItems.length === 0 ? (
              <p className="text-gray-500">Your cart is empty</p>
            ) : (
              <div className="space-y-4">
                {cartItems.map((item) => (
                  <div key={item._id} className="flex items-center justify-between p-4 border rounded-lg">
                    <div>
                      <h3 className="font-semibold">{item.displayName}</h3>
                      <p className="text-gray-600">₹{parseFloat(item.mrp).toFixed(2)} x {item.quantity}</p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleUpdateQuantity(item._id, item.quantity - 1)}
                          className="px-2 py-1 bg-gray-200 rounded-lg"
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={() => handleUpdateQuantity(item._id, item.quantity + 1)}
                          className="px-2 py-1 bg-gray-200 rounded-lg"
                        >
                          +
                        </button>
                      </div>
                      <button
                        onClick={() => handleRemoveFromCart(item._id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="mt-6 pt-4 border-t">
                  <div className="flex justify-between items-center text-xl font-bold">
                    <span>Total:</span>
                    <span>₹{calculateTotal()}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
