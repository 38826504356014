import React, { useState, useEffect } from 'react';
import DashboardLayout from './dashboardLayout';
import axios from 'axios';
import toast from 'react-hot-toast';

function OnlineCall() {
  const [callRequests, setCallRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('all');
  const [error, setError] = useState('');

  const fetchCallRequests = async (status) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/getCallRequestDetails`,
        {
          filterKey: status,
          page: 1,
          size: 5,
        },
        {
          headers: { Authorization: `${sessionStorage.getItem('authToken')}` },
        }
      );
      console.log("responseData is here",response.data.data.callRequestDetails);
      if (response.data.success) {
        setCallRequests(response.data.data.callRequestDetails || []);
      } else {
        setError('Failed to fetch call request details.');
      }
    } catch (err) {
      setError('An error occurred while fetching call request details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCallRequests(filter);
  }, [filter]);

  console.log("callRequests",callRequests);

  return (
    <DashboardLayout>
      <div className="text-3xl font-bold mb-6">
        <h1 className="text-3xl font-bold mb-6">Online Call Requests</h1>

        {/* Filter Buttons */}
        <div className="mb-8 flex space-x-4">
          {['all', 'pending', 'accepted', 'rejected'].map((status) => (
            <button
              key={status}
              className={`px-6 py-2 rounded-full transition duration-200 ${
                filter === status
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
              }`}
              
              onClick={() => setFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ))}
        </div>

        {/* Displaying the call requests */}
        {loading && <div>Loading...</div>}
        {error && <div className="text-red-500">{error}</div>}
        {callRequests.length === 0 ? (
          <div className="text-center text-gray-500">No call requests found</div>
        ) : (
          <div>
            {callRequests.map((request) => (
              <div
                key={request._id}
                className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200"
              >
                <h2 className="font-semibold text-xl mb-4">Call Request ID: {request._id}</h2>
                <p className="text-xl">Status: {request.status}</p>
                <p className="text-md">Merchant Name: {request.merchantName}</p>
                <p className="text-md">Merchant Phone: {request.userDetails.mobileNumber}</p>
                <p className="text-md">Call Time: {new Date(request.callRequestedTime).toLocaleString()}</p>
                {request.status === 'pending' && (
                  <button
                    onClick={() => {
                      if (window.confirm('Are you sure you want to resolve this call request?')) {
                        (async () => {
                          try {
                            const response = await axios.post(
                              `${process.env.REACT_APP_BASE_URL}/admin/admin/resolveCallRequest`,
                              { callRequestId: request._id },
                              {
                                headers: { Authorization: `${sessionStorage.getItem('authToken')}` },
                              }
                            );
                            if (response.data.success) {
                              toast.success('Call request resolved successfully');
                              fetchCallRequests(filter);
                            } else {
                              toast.error('Failed to resolve call request');
                            }
                          } catch (err) {
                            toast.error('An error occurred while resolving the call request');
                          }
                        })();
                      }
                    }}
                    className="mt-4 px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-200"
                  >
                    Resolve
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default OnlineCall;
